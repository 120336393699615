import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/Showcase/layout';
import Seo from '../components/Showcase/seo';
import { StaticImage } from "gatsby-plugin-image"
import {ParallaxProvider, Parallax } from "react-scroll-parallax"


//* Fase 2: Add on-page navigation functionality 
// const handleClick = value => () => {
//   console.log(value)
//   const section = document.querySelector(`${value}`);
//   // console.log(section.id)
//   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
// }

const UxDesign = () => (
  <ParallaxProvider>

  <Layout>
    <Seo title="UX Design: Wireframes, Prototyping & User Testing" />
    <section className="intro-section">
      <span className="tag-line">Page Wireframes Development | Responsive Planning</span>
      <h1>UX Design: Wireframes, Prototyping & User Testing</h1>
      <p>The conceptual work done in earlier phases starts coming to life in the wireframe and prototype stages. As we build these, we consider goals for the new site, technical abilities of the CMS, needs surfaced in the content audit, user preferences, and more. We also <strong>keep post-launch usability in mind,</strong> incorporating dynamic content where possible to minimize the work needed for upkeep and updates.</p>
    </section>
    <section className="banner">
      
      <Parallax translateY={["50", "-30"]}>
     
      <StaticImage
        src="../assets/images/ux-design/wireframes-ideation.jpg"
        width={4292}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Wireframes Ideation"
        />
      </Parallax>
      <figcaption>Wireframes Ideation</figcaption>
    </section>
    <section className="portfolio">
      <p className="intro">Template Content Strategy - Module Functional Spec Planning - Wireframes - Prototypes - User-Testing</p>
      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/ux-design/user-testing.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="User Testing"
        />
        
        </figure>
        <figcaption>User Testing</figcaption>
      </div>

      

      <div className="portfolio-card parallax">
      <Parallax  translateX={[80,- 20]}>
      <figure>
        <StaticImage
        src="../assets/images/ux-design/wireframes-templates.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Wireframes & Landing Page Templates"
        />
        </figure>
        </Parallax>
        <figcaption>Wireframes & Landing Page Templates</figcaption>
      </div>


      <div className="cols">
        <div className="portfolio-card col-50">
          <figure>
          <StaticImage
          src="../assets/images/ux-design/prototyping.jpg"
          width={1280}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Prototyping"
          />
          
          </figure>
          <figcaption>Prototyping</figcaption>
        </div>
        <div className="portfolio-card parallax col-50">
      <figure>
        <StaticImage
        src="../assets/images/ux-design/mobile-wireframes.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Mobile Experience Wireframes"
        />
        
        </figure>
        <figcaption>Mobile Experience Wireframes</figcaption>
      </div>
      </div>

      <div className="portfolio-card parallax">
      <Parallax  translateX={[80,- 20]}>
      <figure>
        <StaticImage
        src="../assets/images/ux-design/custom-ui-kit.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Custom UI Kit"
        />
        </figure>
        </Parallax>
        <figcaption>Custom UI Kit</figcaption>
      </div>

    </section>


    <section className="wrapper">
    <div className="section-title side-title">
        <h2>MORE DESIGN STEPS</h2>
        </div>
    <ul className="content-cards-wrapper">

          <li className="content-card">
            <Link to="/visual-design">
              <StaticImage
                src="../assets/images/visual-design-link.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Visual Design</h3>
                <p>Brand Standards | Design Exploration | Prototyping</p>
              </div>
            </Link>
          </li>

          <li className="content-card">
            <Link to="/content-strategy">
              <StaticImage
                src="../assets/images/on-page-content-strategy-copywriting-production-and-planning.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>On-page Content Strategy, Copywriting & Production Planning</h3>
                <p>Page-by-Page Content Strategy | Copywriting | Interactive Web Content | User Acceptance Testing</p>
              </div>
            </Link>
          </li>
          </ul>
    </section>
    
  </Layout>
  </ParallaxProvider>
)

export default UxDesign
